import React from "react";
import authRoute from "./authRoute";
export const publicRoutes = [...authRoute];

export const protectedRoutes = [
  {
    key: "notfound",
    path: `/notfound`,
    component: React.lazy(() => import("views/NotFound")),
    authority: [],
  },
  {
    key: "_portals",
    path: "/portal",
    component: React.lazy(() => import("views/portal/Portal")),
    authority: [],
    meta: {
      layout: "blank",
    },
  },
  {
    key: "home",
    path: "/home",
    component: React.lazy(() => import("views/Home")),
    authority: [],
  },
  {
    key: "welcome",
    path: "/home/welcome",
    component: React.lazy(() => import("views/home/Welcome")),
    authority: [],
  },
  {
    key: "master",
    path: "/home/master",
    component: React.lazy(() => import("views/home/Master")),
    authority: [],
  },
  {
    key: "entry",
    path: "/home/entry",
    component: React.lazy(() => import("views/home/Entry")),
    authority: [],
  },
  {
    key: "report",
    path: "/home/report",
    component: React.lazy(() => import("views/home/Report")),
    authority: [],
  },
  {
    key: `teacher.activity.report`,
    path: "/academics/teacher/report/teacher-activity-report",
    component: React.lazy(() =>
      import("views/academics/teacher/report/TeacherActivityReport")
    ),
    authority: [],
  },
  // academics routes components
  {
    key: "academics",
    path: "/academics",
    component: React.lazy(() => import("views/Dashboard/Dashboard")),
    authority: [],
  },
  {
    key: `academics.master.course`,
    path: "/academics/master/course/academics-master-course",
    component: React.lazy(() =>
      import("views/academics/attendance/master/AcademicsMasterCourse")
    ),
    authority: [],
  },
  {
    key: `master.session-wise-course`,
    path: "/academics/master/session-wise-course",
    component: React.lazy(() =>
      import("views/academics/attendance/master/SessionWiseCourse")
    ),
    authority: [],
  },
  {
    key: `academics.master.paper`,
    path: "/academics/master/course/academics-master-paper",
    component: React.lazy(() =>
      import("views/academics/attendance/master/AcademicsMasterPaper")
    ),
    authority: [],
  },
  {
    key: `academics.master.dashboard-configuration`,
    path: "/settings/master/dashboard-configuration",
    component: React.lazy(() =>
      import("views/academics/attendance/master/DashboardConfig")
    ),
    authority: [],
  },
  {
    key: `academics.master.configure-dashboard`,
    path: "/academics/master/configure-dashboard",
    component: React.lazy(() =>
      import("views/academics/attendance/master/ConfigureDashboard")
    ),
    authority: [],
  },
  {
    key: `master.module-semester`,
    path: "/academics/master/module-semester",
    component: React.lazy(() =>
      import("views/academics/attendance/master/ModuleSemester")
    ),
    authority: [],
  },
  {
    key: `master.course-paper-tag`,
    path: "/academics/master/course-paper-tag",
    component: React.lazy(() =>
      import("views/academics/attendance/master/CoursePaperTag")
    ),
    authority: [],
  },
  {
    key: `master.paper-wise-topic`,
    path: "/academics/master/paper-wise-topic",
    component: React.lazy(() =>
      import("views/academics/attendance/master/PaperWiseTopic")
    ),
    authority: [],
  },
  {
    key: `master.attendance-lock`,
    path: "/academics/master/attendance-lock",
    component: React.lazy(() =>
      import("views/academics/attendance/master/AttendanceLock")
    ),
    authority: [],
  },
  {
    key: `academics.attendance.update`,
    path: "/academics/attendance-update",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/AttendanceUpdate")
    ),
    authority: [],
  },
  {
    key: `academics.master.user-management`,
    path: "/settings/master/user-management",
    component: React.lazy(() =>
      import("views/academics/attendance/master/UserManagement/UserManagement")
    ),
    authority: [],
  },
  {
    key: `settings.master.run-script`,
    path: "/settings/master/run-script",
    component: React.lazy(() =>
      import("views/academics/attendance/master/RunScript/RunScript")
    ),
    authority: [],
  },
  {
    key: `master.user-management.user-creation`,
    path: "/academics/master/user",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/master/AcademicUserManagement/AcademicUserManagement"
      )
    ),
    authority: [],
  },
  {
    key: `lecture.duration`,
    path: "/academics/master/course/lecture-duration",
    component: React.lazy(() =>
      import("views/academics/attendance/master/LectureDuration")
    ),
    authority: [],
  },
  {
    key: `master.add-menu-list`,
    path: "/settings/master/add-menu-list",
    component: React.lazy(() =>
      import("views/academics/attendance/master/AddMenuList/AddMenuList")
    ),
    authority: [],
  },
  {
    key: `role.permission`,
    path: "/settings/master/role-permission",
    component: React.lazy(() =>
      import("views/academics/attendance/master/RolePermission/RolePermission")
    ),
    authority: [],
  },
  {
    key: `university.attendance.report`,
    path: "/academics/attendance/report/university-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/Attendence/UniversityWiseAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `school.attendance.report`,
    path: "/academics/attendance/report/school-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/Attendence/SchoolWiseAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `academics.attendance.report.department.attendance`,
    path: "/academics/attendance/report/department-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/Attendence/DepartmentWiseAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `university.group.wise.attendance.report`,
    path: "/academics/attendance/report/university-group-wise-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/GroupWiseAttendanceReport/UniversityCourseGroupWiseAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `school.group.wise.attendance.report`,
    path: "/academics/attendance/report/school-group-wise-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/GroupWiseAttendanceReport/SchoolGroupWiseAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `department.group.wise.attendance.report`,
    path: "/academics/attendance/report/department-group-wise-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/GroupWiseAttendanceReport/DepartmentGroupWiseAttdance"
      )
    ),
    authority: [],
  },
  {
    key: `university.current.previous.attendance.report`,
    path: "/academics/attendance/report/university-current-previous-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/CurrPreAttendance/UniversityCurrPreAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `school.current.previous.attendance.report`,
    path: "/academics/attendance/report/school-current-previous-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/CurrPreAttendance/SchoolCurrPreAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `department.current.previous.attendance.report`,
    path: "/academics/attendance/report/department-current-previous-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/CurrPreAttendance/DepartmentCurPreAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `department.average.attendance.percentage.report`,
    path: "/academics/attendance/report/department-average-attendance-percentage-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/DepartmentAverageAttendancePercentageReport"
      )
    ),
    authority: [],
  },
  {
    key: `school.wise.average.attendance.report`,
    path: "/academics/attendance/report/school-wise-average-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/AverageAttendanceReport/SchoolWiseAverageAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `department.wise.average.attendance.report`,
    path: "/academics/attendance/report/department-wise-average-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/AverageAttendanceReport/DepartmentWiseAverageAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `student.course.wise.attendance.report`,
    path: "/academics/attendance/report/student-course-wise-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/StudentAttendenceReport/StudentWiseAverageAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `student.report.sis-report.admission-list`,
    path: "/students/report/sis-report/admission-list",
    component: React.lazy(() =>
      import("views/students/Report/sisReport/admissionList/AdmissionList")
    ),
    authority: [],
  },
  {
    key: `transfer.student.course.wise.attendance.report`,
    path: "/academics/attendance/report/transfer-student-course-wise-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/StudentAttendenceReport/TransferStudentWiseAverageAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `school.attendance.below.specified.percentage.report`,
    path: "/academics/attendance/report/school-attendance-below-specified-percentage-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/BelowSpecifiedAttendence/SchoolAttendanceBelowSpecifiedPercentage"
      )
    ),
    authority: [],
  },
  {
    key: `department.attendance.below.specified.percentage.report`,
    path: "/academics/attendance/report/department-attendance-below-specified-percentage-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/BelowSpecifiedAttendence/DepartmentBelowAttendance"
      )
    ),
    authority: [],
  },
  {
    key: `school.attendance.below.specified.percentage.individual.course.report`,
    path: "/academics/attendance/report/school-attendance-below-specified-percentage-individual-course-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/BelowSpecifiedAttendence/SchoolAttendanceBelowSpecifiedPercentageIndividualCourseReport"
      )
    ),
    authority: [],
  },
  {
    key: `batch.attendance.below.specified.percentage.individual.course.report`,
    path: "/academics/attendance/report/batch-attendance-below-specified-percentage-individual-course-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/BelowSpecifiedAttendence/BatchAttendanceBelowSpecifiedPercentageIndividualCourseReport"
      )
    ),
    authority: [],
  },
  {
    key: `school.teacher.wise.student.attendance.report`,
    path: "/academics/attendance/report/school-teacher-wise-student-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/BelowSpecifiedAttendence/SchoolTeacherWiseStudentAttendance"
      )
    ),
    authority: [],
  },
  {
    key: `department.teacher.not.submit.activity.report`,
    path: "/academics/attendance/report/department-teacher-not-submit-activity-report",
    component: React.lazy(() =>
      import("views/academics/teacher/report/TeacherActivity")
    ),
    authority: [],
  },
  {
    key: `teacher.wise.student.attendance.report`,
    path: "/academics/attendance/report/teacher-wise-student-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/TeacherWiseStudentAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `teacher.wise.student.attendance.report.f2`,
    path: "/academics/attendance/report/teacher-wise-student-attendance-report-f2",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/TeacherWiseF2StudentAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `department.teacher.daily.activity.report`,
    path: "/academics/attendance/report/department-teacher-daily-activity-report",
    component: React.lazy(() =>
      import(
        "views/academics/teacher/report/TeacherDailyActivity/TeacherDailyActivity"
      )
    ),
    authority: [],
  },
  {
    key: `department.teacher.activity.report`,
    path: "/academics/attendance/report/department-teacher-activity-report",
    component: React.lazy(() =>
      import(
        "views/academics/teacher/report/DepartmentTeacherActivity/DepartmentTeacherActivity"
      )
    ),
    authority: [],
  },
  {
    key: `teacher.wise.student.attendance.exception.report`,
    path: "/academics/attendance/report/teacher-wise-student-attendance-exception-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/BelowSpecifiedAttendence/TeacherWiseStudentAttendanceExceptionReport"
      )
    ),
    authority: [],
  },
  {
    key: `department.activity.progress.report`,
    path: "/academics/department/report/department-activity-report",
    component: React.lazy(() =>
      import("views/academics/department/report/DepartmentActivityReport")
    ),
    authority: [],
  },
  {
    key: `batch.wise.student.attendance.report`,
    path: "/academics/attendance/report/batch-wise-student-attendance-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/BatchWiseStudentAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `program.wise.teaching.leading.feedback.score`,
    path: "/academics/feedback/report/program-wise-teaching-leading-feedback-score",
    component: React.lazy(() =>
      import(
        "views/academics/feedback/report/ProgramWiseTeachingLeadingFeedbackScore"
      )
    ),
    authority: [],
  },
  {
    key: `program.wise.teaching.leading.feedback.score.amenity`,
    path: "/academics/feedback/report/amenity/program-wise-teaching-leading-feedback-score",
    component: React.lazy(() =>
      import(
        "views/academics/feedback/report/AminityFeedback/ProgramWiseTeachingLeadingFeedbackScore"
      )
    ),
    authority: [],
  },
  {
    key: `centre.report.school.wise.feedback.score`,
    path: "/academics/feedback/report/centre-report-school-wise-feedback-score",
    component: React.lazy(() =>
      import(
        "views/academics/feedback/report/CenterReportSchoolWiseFeedbackScore"
      )
    ),
    authority: [],
  },
  {
    key: `centre.report.school.wise.student.feedback.analysis`,
    path: "/academics/feedback/report/centre-report-school-wise-student-feedback-analysis",
    component: React.lazy(() =>
      import(
        "views/academics/feedback/report/CenterReportSchoolWiseStudentFeedbackAnalysis"
      )
    ),
    authority: [],
  },
  {
    key: `centre.report.school.wise.student.feedback.analysis.amenity`,
    path: "/academics/feedback/report/amenity/centre-report-school-wise-student-feedback-analysis",
    component: React.lazy(() =>
      import(
        "views/academics/feedback/report/AminityFeedback/CenterReportSchoolWiseStudentFeedbackAnalysis"
      )
    ),
    authority: [],
  },
  {
    key: `centre.report.school.wise.feedback.analysis`,
    path: "/academics/feedback/report/centre-report-school-wise-feedback-analysis",
    component: React.lazy(() =>
      import(
        "views/academics/feedback/report/CenterReportSchoolWiseFeedbackAnalysis"
      )
    ),
    authority: [],
  },
  {
    key: `centre.report.feedback.analysis.all.school.wise`,
    path: "/academics/feedback/report/centre-report-feedback-analysis-all-school-wise",
    component: React.lazy(() =>
      import("views/academics/feedback/report/FeedbackAnalysisSchoolWise")
    ),
    authority: [],
  },
  {
    key: `centre.report.feedback.analysis.department.wise`,
    path: "/academics/feedback/report/centre-report-feedback-analysis-department-wise",
    component: React.lazy(() =>
      import(
        "views/academics/feedback/report/CenterReportDepartmentWiseFeedbackAnalysis"
      )
    ),
    authority: [],
  },
  {
    key: `centre.report.feedback.analysis.department.wise.amenity`,
    path: "/academics/feedback/report/amenity/centre-report-feedback-analysis-department-wise",
    component: React.lazy(() =>
      import(
        "views/academics/feedback/report/AminityFeedback/CenterReportDepartmentWiseFeedbackAnalysis"
      )
    ),
    authority: [],
  },
  {
    key: `centre.report.feedback.analysis.all.department.wise`,
    path: "/academics/feedback/report/centre-report-feedback-analysis-all-department-wise",
    component: React.lazy(() =>
      import(
        "views/academics/feedback/report/CenterReportAllDepartmentWiseFeedbackAnalysis"
      )
    ),
    authority: [],
  },
  {
    key: `center.report.batch.wise.student.feedback.count`,
    path: "/academics/feedback/report/center-report-batch-wise-student-feedback-count",
    component: React.lazy(() =>
      import(
        "views/academics/feedback/report/CenterReportBatchWiseStudentFeedbackCount"
      )
    ),
    authority: [],
  },
  {
    key: `center.report.department.wise.student.feedback.analysis`,
    path: "/academics/feedback/report/center-report-department-wise-student-feedback-analysis",
    component: React.lazy(() =>
      import(
        "views/academics/feedback/report/CenterReportDepartmentWiseStudentFeedbackAnalysis"
      )
    ),
    authority: [],
  },
  {
    key: `center.report.department.wise.student.feedback.analysis.amenity`,
    path: "/academics/feedback/report/amenity/center-report-department-wise-student-feedback-analysis",
    component: React.lazy(() =>
      import(
        "views/academics/feedback/report/AminityFeedback/CenterReportDepartmentWiseStudentFeedbackAnalysis"
      )
    ),
    authority: [],
  },
  {
    key: `dropout.student.list`,
    path: "/academics/student/report/dropout-student-list",
    component: React.lazy(() =>
      import(
        "views/academics/studentInfo/report/DropoutStudentList/DropOutStudentList"
      )
    ),
    authority: [],
  },
  {
    key: `student.self.information`,
    path: "/academics/student/report/student-self-information",
    component: React.lazy(() =>
      import(
        "views/academics/studentInfo/report/StudentSelfInformation/StudentSelfInfo"
      )
    ),
    authority: [],
  },
  {
    key: `admin.faculty.note.upload`,
    path: "/academics/student/report/admin-faculty-note-upload",
    component: React.lazy(() =>
      import(
        "views/academics/studentInfo/report/FacultyNoteUpload/FacultyNoteUpload"
      )
    ),
    authority: [],
  },
  {
    key: `admin.attendance.resource.upload.resource.exception`,
    path: "/academics/attendance/resource/upload-resource-exception",
    component: React.lazy(() =>
      import(
        "views/academics/studentInfo/report/UploadResourceException/UploadResourceException"
      )
    ),
    authority: [],
  },
  {
    key: `day.wise.campus.student.count`,
    path: "/academics/student/report/day-wise-campus-student-count",
    component: React.lazy(() =>
      import("views/academics/studentInfo/report/DayWiseCampusStudentCount")
    ),
    authority: [],
  },
  {
    key: `month.wise.campus.student.count`,
    path: "/academics/student/report/month-wise-campus-student-count",
    component: React.lazy(() =>
      import("views/academics/studentInfo/report/MonthWiseCampusStudentCount")
    ),
    authority: [],
  },
  {
    key: `batch.wise.course.wise.report`,
    path: "/academics/student/report/batch-wise-course-wise-report",
    component: React.lazy(() =>
      import("views/academics/studentInfo/report/BatchWiseCourseWiseReport")
    ),
    authority: [],
  },
  {
    key: `teacher.wise.batch.wise.report`,
    path: "/academics/student/report/teacher-wise-batch-wise-report",
    component: React.lazy(() =>
      import("views/academics/studentInfo/report/TeacherWiseBatchReport")
    ),
    authority: [],
  },
  {
    key: `center.student.attendance.sms`,
    path: "/academics/student/report/center-student-attendance-sms",
    component: React.lazy(() =>
      import("views/academics/studentInfo/report/CenterStudentAttendanceSMS")
    ),
    authority: [],
  },
  {
    key: `center.student.attendance.sms-send-report`,
    path: "/academics/student/report/sms-send-report",
    component: React.lazy(() =>
      import("views/academics/studentInfo/report/SendReportSMS")
    ),
    authority: [],
  },
  {
    key: `center.student.attendance.sms-based-attendance`,
    path: "/academics/student/report/sms-based-attendance",
    component: React.lazy(() =>
      import("views/academics/studentInfo/report/SMSBasedAttendance")
    ),
    authority: [],
  },
  {
    key: `teacher.class.load.report`,
    path: "/academics/student/report/teacher-class-load-report",
    component: React.lazy(() =>
      import("views/academics/studentInfo/report/TeacherClassLoadReport")
    ),
    authority: [],
  },
  {
    key: `student.report.information-session`,
    path: "/academics/student/report/session-information",
    component: React.lazy(() =>
      import(
        "views/academics/studentInfo/report/SessionWiseStudentReport/SessionWiseStudentReport"
      )
    ),
    authority: [],
  },
  {
    key: `student.report.semester-attendance`,
    path: "/academics/student/report/semester-attendance",
    component: React.lazy(() =>
      import(
        "views/academics/studentInfo/report/StudentSemesterAttendance/StudentSemesterAttendance"
      )
    ),
    authority: [],
  },
  {
    key: `student.report.mar.semester-wise`,
    path: "/academics/student/report/mar/semester-wise",
    component: React.lazy(() =>
      import(
        "views/academics/studentInfo/report/SemesterWiseMarReport/SemesterWiseMarReport"
      )
    ),
    authority: [],
  },
  {
    key: `student.report.mar.year-wise`,
    path: "/academics/student/report/mar/year-wise",
    component: React.lazy(() =>
      import(
        "views/academics/studentInfo/report/YearWiseMarReport/YearWiseMarReport"
      )
    ),
    authority: [],
  },
  {
    key: `student.activity.event.creation`,
    path: "/academics/student/report/student-activity-event-creation",
    component: React.lazy(() =>
      import("views/academics/studentInfo/report/StudentActivityEventCreation")
    ),
    authority: [],
  },
  {
    key: `university.syllabus.completion.report`,
    path: "/academics/syllabus/report/university-syllabus-completion-report",
    component: React.lazy(() =>
      import(
        "views/academics/syllabus/report/UniversitySyllabusCompletionReport"
      )
    ),
    authority: [],
  },
  {
    key: `university.syllabus.completion.class.record`,
    path: "/academics/syllabus/daily-class-record",
    component: React.lazy(() =>
      import("views/academics/syllabus/report/DailyClassReport")
    ),
    authority: [],
  },
  {
    key: `department.syllabus.completion.report`,
    path: "/academics/syllabus/report/department-syllabus-completion-report",
    component: React.lazy(() =>
      import(
        "views/academics/syllabus/report/DepartmentSyllabusCompletionReport"
      )
    ),
    authority: [],
  },
  {
    key: `batch.syllabus.completion.report`,
    path: "/academics/syllabus/report/batch-syllabus-completion-report",
    component: React.lazy(() =>
      import("views/academics/syllabus/report/BatchSyllabusCompletionReport")
    ),
    authority: [],
  },
  {
    key: `department.attendance.marks.tracker`,
    path: "/academics/attendance/report/department-attendance-marks-tracker",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/DepartmentAttendanceMarksTracker"
      )
    ),
    authority: [],
  },
  {
    key: `department.attendance.report.lecture-plan`,
    path: "/academics/attendance/report/lecture-plan",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/LecturePlanReport/LecturePlanReport"
      )
    ),
    authority: [],
  },
  {
    key: `new.batch.entry`,
    path: "/academics/attendance/entry/new-batch-entry",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/NewBatchEntry")
    ),
    authority: [],
  },
  {
    key: `assign.student.batch.entry`,
    path: "/academics/attendance/entry/student-batch-entry",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/AssignStudentBatch")
    ),
    authority: [],
  },
  {
    key: `assign.student.group.entry`,
    path: "/academics/attendance/entry/student-group-entry",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/AssignStudentGroup")
    ),
    authority: [],
  },
  {
    key: `entry.batch.student-batch-unassign`,
    path: "/academics/entry/batch/student-batch-unassign",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/UnassignStudentBatch")
    ),
    authority: [],
  },
  {
    key: `department.teacher.leaving.status.entry`,
    path: "/academics/attendance/entry/teacher-leaving-status-entry",
    component: React.lazy(() =>
      import("views/academics/teacher/entry/TeacherLeavingStatusEntry")
    ),
    authority: [],
  },
  {
    key: `admin.teacher.activity.entry`,
    path: "/academics/attendance/entry/admin-teacher-activity-entry",
    component: React.lazy(() =>
      import("views/academics/teacher/entry/AdminTeacherActivity")
    ),
    authority: [],
  },
  {
    key: `teacher.activity.entry.list`,
    path: "/academics/attendance/entry/teacher-activity-entry-list",
    component: React.lazy(() =>
      import("views/academics/teacher/entry/TeacherActivityEntryList")
    ),
    authority: [],
  },
  {
    key: `teacher.activity.entry.list-v2`,
    path: "/academics/attendance/entry/teacher-activity-entry-list-v2",
    component: React.lazy(() =>
      import("views/academics/teacher/entry/TeacherActivityEntryListV2")
    ),
    authority: [],
  },
  {
    key: `teacher.daily.activity.entry.tab`,
    path: "/academics/attendance/entry/teacher-daily-activity-entry-tab",
    component: React.lazy(() =>
      import("views/academics/teacher/entry/TeacherActivityEntryList/tab")
    ),
    authority: [],
  },
  {
    key: `teacher.daily.activity.entry.tab-v2`,
    path: "/academics/attendance/entry/teacher-daily-activity-entry-tab-v2",
    component: React.lazy(() =>
      import("views/academics/teacher/entry/TeacherActivityEntryListV2/tab")
    ),
    authority: [],
  },
  {
    key: `session.wise.attendance.period`,
    path: "/academics/attendance/entry/session-wise-attendance-period",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/SessionWisePeriod")
    ),
    authority: [],
  },
  {
    key: `academics.mlp.status.report`,
    path: "/academics/attendance/entry/mlp-status-report",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/MLPStatusReport/MLPStatusReport")
    ),
    authority: [],
  },
  {
    key: `academics.report.class-break-up-report`,
    path: "/academics/attendance/entry/class-break-up-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/entry/ClassBreakupReport/ClassBreakupReport"
      )
    ),
    authority: [],
  },
  {
    key: `assign.student.elective.paper`,
    path: "/academics/attendance/entry/assign-student-elective-paper",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/AssignStudentElectivePaper")
    ),
    authority: [],
  },
  {
    key: `batch.wise-student.paper.list`,
    path: "/academics/attendance/entry/batch-wise-paper-list",
    component: React.lazy(() =>
      import("views/academics/lecture/Entry/BatchWisePaperList")
    ),
    authority: [],
  },
  {
    key: `batch.wise-student.paper.list.v2`,
    path: "/academics/attendance/entry/batch-wise-paper-list-v2",
    component: React.lazy(() =>
      import(
        "views/academics/lecture/Entry/BatchWiseLecturePlanEntry/BatchWiseLecturePlanEntry"
      )
    ),
    authority: [],
  },
  {
    key: `batch.wise-student.paper.list.v2.modify`,
    path: "/academics/attendance/modify/batch-wise-paper-list-v2",
    component: React.lazy(() =>
      import(
        "views/academics/lecture/Entry/BatchWiseLecturePlanEntry/BatchWiseLecturePlanModify"
      )
    ),
    authority: [],
  },
  {
    key: `department.activity.control`,
    path: "/academics/department/entry/department-activity-control",
    component: React.lazy(() =>
      import("views/academics/department/entry/DepartmentActivityControl")
    ),
    authority: [],
  },
  {
    key: `department.activity.entry`,
    path: "/academics/department/entry/department-activity-entry",
    component: React.lazy(() =>
      import("views/academics/department/entry/DepartmentActivityEntry")
    ),
    authority: [],
  },
  {
    key: `student.batch.wise.attendance`,
    path: "/academics/attendance/entry/batch-wise-attendance",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/BatchWiseAttendance")
    ),
    authority: [],
  },
  {
    key: `student.batch.wise.attendance.yearlag`,
    path: "/academics/attendance/entry/batch-wise-attendance-yearlag",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/BatchWiseBacklogAttendance")
    ),
    authority: [],
  },
  {
    key: `department.teacher.class.note.upload`,
    path: "/academics/attendance/entry/class-note-upload",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/ClassNoteUpload/ClassNoteUpload")
    ),
    authority: [],
  },
  {
    key: `department.teacher.class.syllabus.upload`,
    path: "/academics/attendance/entry/class-syllabus-upload",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/entry/ClassSyllabusUpload/ClassSyllabusUpload"
      )
    ),
    authority: [],
  },
  {
    key: `master.attendance.notice-category`,
    path: "/academics/master/notice-category",
    component: React.lazy(() =>
      import("views/academics/attendance/master/NoticeCategory")
    ),
    authority: [],
  },
  {
    key: `master.attendance.role-wise-notice-category`,
    path: "/academics/master/role-wise-notice-category",
    component: React.lazy(() =>
      import("views/academics/attendance/master/RoleWiseNoticeCategory")
    ),
    authority: [],
  },
  {
    key: `master.attendance.notice-upload`,
    path: "/academics/attendance/entry/notice-upload",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/NoticeUpload")
    ),
    authority: [],
  },
  {
    key: `master.attendance.notice-query`,
    path: "/academics/attendance/entry/notice-query",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/NoticeQuery")
    ),
    authority: [],
  },
  // {
  //     key: `academics${random}`,
  //     path: "/academics/:subPortal/:url",
  //     component: React.lazy(() => import("views/Academics")),
  //     authority: [],
  // },

  // students routes components
  {
    key: "students",
    path: "/students",
    component: React.lazy(() => import("views/Students")),
    authority: [],
  },
  {
    key: "students.master.fee-head",
    path: "/students/master/fee-head",
    component: React.lazy(() => import("views/students/master/FeeHead")),
    authority: [],
  },
  {
    key: "students.master.fee-structure-degree",
    path: "/students/master/fee-structure-degree",
    component: React.lazy(() =>
      import("views/students/master/FeeStructureDegree")
    ),
    authority: [],
  },
  {
    key: "students.master.fee-structure-diploma",
    path: "/students/master/fee-structure-diploma",
    component: React.lazy(() =>
      import("views/students/master/FeeStructureDiploma")
    ),
    authority: [],
  },
  {
    key: "students.entry.student.student-profile",
    path: "/students/entry/student/student-profile",
    component: React.lazy(() =>
      import("views/students/entry/student/StudentProfile/StudentProfile")
    ),
    authority: [],
  },

  // mentor routes components
  {
    key: "mentor",
    path: "/mentor",
    component: React.lazy(() => import("views/Mentor")),
    authority: [],
  },
  {
    key: "mentor.master.create-mentor",
    path: "/mentor/master/create-mentor",
    component: React.lazy(() =>
      import("views/mentor/master/createMentor/createMentor")
    ),
    authority: [],
  },
  {
    key: "mentor.entry.student.assignment",
    path: "/mentor/entry/student-assignment",
    component: React.lazy(() =>
      import("views/mentor/entry/studentAssignment/studentAssignment")
    ),
    authority: [],
  },
  {
    key: "mentor.entry.student.movement",
    path: "/mentor/entry/student-movement",
    component: React.lazy(() =>
      import("views/mentor/entry/studentMovement/studentMovement")
    ),
    authority: [],
  },
  {
    key: "mentor.entry.mentoring.schedule",
    path: "/mentor/entry/mentoring-schedule",
    component: React.lazy(() =>
      import("views/mentor/entry/mentoringSchedule/mentoringSchedule")
    ),
    authority: [],
  },
  {
    key: "mentor.entry.mentoring.schedule.details",
    path: "/mentor/entry/mentoring-schedule-details",
    component: React.lazy(() =>
      import(
        "views/mentor/entry/mentoringScheduleDetails/mentoringScheduleDetails"
      )
    ),
    authority: [],
  },
  {
    key: "mentor.report.mentoring.schedule.report",
    path: "/mentor/report/mentoring-schedule-report",
    component: React.lazy(() =>
      import(
        "views/mentor/report/mentoringScheduleReport/mentoringScheduleReport"
      )
    ),
    authority: [],
  },
  {
    key: "mentor.entry.student.mentoring",
    path: "/mentor/entry/student-mentoring",
    component: React.lazy(() =>
      import("views/mentor/entry/studentMentoring/studentMentoring")
    ),
    authority: [],
  },
  {
    key: "mentor.report.mentoring.data",
    path: "/mentor/report/mentoring-data",
    component: React.lazy(() =>
      import("views/mentor/report/mentoringData/mentoringData")
    ),
    authority: [],
  },
  {
    key: "mentor.report.mentoring.attendance",
    path: "/mentor/report/mentoring-attendance",
    component: React.lazy(() =>
      import("views/mentor/report/mentoringAttendance/mentoringAttendance")
    ),
    authority: [],
  },
  {
    key: "mentor.report.mentoring.marks",
    path: "/mentor/report/mentoring-marks",
    component: React.lazy(() =>
      import("views/mentor/report/mentoringMarks/mentoringMarks")
    ),
    authority: [],
  },
  {
    key: "mentor.report.mentoring.marks.details",
    path: "/mentor/report/mentoring-marks-details",
    component: React.lazy(() =>
      import("views/mentor/report/mentoringMarksDetails/mentoringMarksDetails")
    ),
    authority: [],
  },
  {
    key: "mentor.report.university-mentoring-report",
    path: "/mentor/report/university-mentoring-report",
    component: React.lazy(() =>
      import(
        "views/mentor/report/UniversityMentoringReport/UniversityMentoringReport"
      )
    ),
    authority: [],
  },
  {
    key: "mentor.report.mentoring-progress-report",
    path: "/mentor/report/mentoring-progress-report",
    component: React.lazy(() =>
      import(
        "views/mentor/report/mentoringProgressReport/mentoringProgressReport"
      )
    ),
    authority: [],
  },
  {
    key: "mentor.report.student-course-wise-attendance-report",
    path: "/mentor/report/student-course-wise-attendance-report",
    component: React.lazy(() =>
      import(
        "views/mentor/report/StudentWiseAttendanceReport/StudentWiseAttendanceReport"
      )
    ),
    authority: [],
  },
  {
    key: `mentor.report.batch-syllabus-completion-report`,
    path: "/mentor/report/batch-syllabus-completion-report",
    component: React.lazy(() =>
      import(
        "views/mentor/report/BatchSyllabusCompletionReport/BatchSyllabusCompletionReport"
      )
    ),
    authority: [],
  },

  /** placement routes components**/
  {
    key: "placement",
    path: "/placement",
    component: React.lazy(() => import("views/Placement")),
    authority: [],
  },

  /** hostel routes components**/
  {
    key: "hostel",
    path: "/hostel",
    component: React.lazy(() => import("views/Hostel")),
    authority: [],
  },
  /*Exam route components */
  {
    key: "exam",
    path: "/exam",
    component: React.lazy(() => import("views/Exam")),
    authority: [],
  },

  /** hostel routes components**/
  {
    key: "settings",
    path: "/academics/dashboard-settings",
    component: React.lazy(() => import("views/Settings/DashboardSettings")),
    authority: [],
  },

  /** Example purpose only, please remove **/
  {
    key: "singleMenuItem",
    path: "/single-menu-view",
    component: React.lazy(() => import("views/demo/SingleMenuView")),
    authority: [],
  },
  {
    key: "collapseMenu.item1",
    path: "/collapse-menu-item-view-1",
    component: React.lazy(() => import("views/demo/CollapseMenuItemView1")),
    authority: [],
  },
  {
    key: "collapseMenu.item2",
    path: "/collapse-menu-item-view-2",
    component: React.lazy(() => import("views/demo/CollapseMenuItemView2")),
    authority: [],
  },
  {
    key: "groupMenu.single",
    path: "/group-single-menu-item-view",
    component: React.lazy(() => import("views/demo/GroupSingleMenuItemView")),
    authority: [],
  },
  {
    key: "groupMenu.collapse.item1",
    path: "/group-collapse-menu-item-view-1",
    component: React.lazy(() =>
      import("views/demo/GroupCollapseMenuItemView1")
    ),
    authority: [],
  },
  {
    key: "groupMenu.collapse.item2",
    path: "/group-collapse-menu-item-view-2",
    component: React.lazy(() =>
      import("views/demo/GroupCollapseMenuItemView2")
    ),
    authority: [],
  },
  {
    key: "departmental.activity.department-target-actual-activity",
    path: "/academics/attendance/report/department-target-actual-activity",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/DepartmentalActivity/DepartmentTargetActualActivity/DepartmentTargetActualActivity"
      )
    ),
    authority: [],
  },
  {
    key: "departmental.activity.department-detailed-target-achieved",
    path: "/academics/attendance/report/department-detailed-target-achieved",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/DepartmentalActivity/DepartmentTargetAchievedReport/DepartmentTargetAchievedReport"
      )
    ),
    authority: [],
  },
  {
    key: "departmental.activity.department-teacher-wise-achievement-report",
    path: "/academics/attendance/report/department-teacher-wise-achievement-report",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/DepartmentalActivity/DepartmentTeacherAchievementReport/DepartmentTeacherAchievementReport"
      )
    ),
    authority: [],
  },
  {
    key: "entry.schedule.teacher",
    path: "/academics/attendance/entry/schedule-teacher",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/ScheduleTeacher/ScheduleTeacher")
    ),
    authority: [],
  },
  {
    key: "entry.teacher.paper.tagging",
    path: "/academics/attendance/entry/teacher-paper-tagging",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/entry/TeacherPaperTagging/TeacherPaperTagging"
      )
    ),
    authority: [],
  },
  {
    key: "entry.batch.assign-paper-group",
    path: "/academics/entry/batch/assign-paper-group",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/AssignPaperGroup")
    ),
    authority: [],
  },
  {
    key: "entry.batch.group-transfer",
    path: "/academics/entry/batch/group-transfer",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/GroupTransfer")
    ),
    authority: [],
  },
  {
    key: "entry.batch.batch-transfer",
    path: "/academics/entry/batch/batch-transfer",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/BatchTransfer")
    ),
    authority: [],
  },
  {
    key: "entry.batch.marks-records",
    path: "/academics/entry/batch/marks-records",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/MarksRecords")
    ),
    authority: [],
  },
  {
    key: "summery.attendance.report",
    path: "/academics/attendance/report/summery-vc",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/SummeryAttendanceReport/SummeryAttendanceVCReport/SummeryAttendanceVCReport"
      )
    ),
    authority: [],
  },
  {
    key: "summery.attendance.report",
    path: "/academics/attendance/report/summery-dean",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/SummeryAttendanceReport/SummeryAttendanceDeanReport/SummeryAttendanceDeanReport"
      )
    ),
    authority: [],
  },
  {
    key: "summery.attendance.report",
    path: "/academics/attendance/report/summery-hod",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/report/SummeryAttendanceReport/SummeryAttendanceHODReport/SummeryAttendanceHODReport"
      )
    ),
    authority: [],
  },
  {
    key: "student.report.semster-wise-elective-paper",
    path: "/academics/student/report/semster-wise-elective-paper",
    component: React.lazy(() =>
      import(
        "views/academics/studentInfo/report/ProgrammeWiseElectiveCourse/ProgrammeWiseElectiveCourse"
      )
    ),
    authority: [],
  },
  {
    key: "student.report.batch-wise-elective-course",
    path: "/academics/student/report/batch-wise-elective-course",
    component: React.lazy(() =>
      import(
        "views/academics/studentInfo/report/BatchWiseElectiveCourse/BatchWiseElectiveCourse"
      )
    ),
    authority: [],
  },
  {
    key: "center.report.student.feedback.comparison",
    path: "/academics/feedback/report/center-student-feedback-comparison",
    component: React.lazy(() =>
      import(
        "views/academics/feedback/report/StudentFeedbackReport/StudentFeedbackReport"
      )
    ),
    authority: [],
  },
  {
    key: "master.class-wise-attendance-lock-withdraw",
    path: "/academics/master/class-wise-attendance-lock-withdraw",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/master/ClassWiseAttendanceLockWithdraw/ClassWiseAttendanceLockWithdraw"
      )
    ),
    authority: [],
  },
  {
    key: "master.class-wise-attendance-delete",
    path: "/academics/master/class-wise-attendance-delete",
    component: React.lazy(() =>
      import(
        "views/academics/attendance/master/ClassWiseAttendanceDelete/ClassWiseAttendanceDelete"
      )
    ),
    authority: [],
  },
  {
    key: `academics.mlp.delete`,
    path: "/academics/attendance/entry/mlp-delete",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/MLPDelete")
    ),
    authority: [],
  },
  {
    key: "academics.report.class-report",
    path: "/academics/attendance/entry/class-report",
    component: React.lazy(() =>
      import("views/academics/attendance/entry/ClassReport/ClassReport")
    ),
    authority: [],
  },
  {
    key: "settings",
    path: "/settings",
    component: React.lazy(() => import("views/ConfigSettings")),
    authority: [],
  },
  // {
  //   key: "exam.emtry.marks-entry",
  //   path: "/exam/entry/marks-records",
  //   component: React.lazy(() => import("views/exam/Report/AssegmentReport")),
  //   authority: [],
  // },
  {
    key: "master.attendance.xyz",
    path: "/academics/master/xyz",
    component: React.lazy(() =>
      import("views/exam/Report/AssegmentReport/BatchWiseReport")
    ),
    authority: [],
  },
  {
    key: "exam.master.xyz",
    path: "/exam/master/xyz",
    component: React.lazy(() =>
      import("views/exam/Report/AssegmentReport/BatchWiseReport")
    ),
    authority: [],
  },
  {
    key: "exam.report.assegment-report/batch-wise-report",
    path: "/exam/report/assegment-report/batch-wise-report",
    component: React.lazy(() =>
      import("views/exam/Report/AssegmentReport/BatchWiseReport")
    ),
    authority: [],
  },
  {
    key: "exam.report.assegment-report/batch-wise-report-pharma",
    path: "/exam/report/assegment-report/batch-wise-report-pharma",
    component: React.lazy(() =>
      import(
        "views/exam/Report/AssegmentReport/BatchWiseReportPharmacy/MarksRecords"
      )
    ),
    authority: [],
  },
  {
    key: "exam.entry.course.examination",
    path: "/exam/entry/course/examination",
    component: React.lazy(() =>
      import("views/exam/entry/course/Examination/ExaminationComponent")
    ),
    authority: [],
  },
  {
    key: "exam.entry.course.paper-wise-marks",
    path: "/exam/entry/course/paper-wise-marks",
    component: React.lazy(() =>
      import("views/exam/entry/course/PaperWiseMarks/PaperWiseMarks")
    ),
    authority: [],
  },
  {
    key: "exam.master.programme.exam-category",
    path: "/exam/master/programme/exam-category",
    component: React.lazy(() =>
      import("views/exam/master/programme/ExamCategory/ExamCategory")
    ),
    authority: [],
  },
  {
    key: "exam.master.programme.exam-configuration",
    path: "/exam/master/programme/exam-configuration",
    component: React.lazy(() =>
      import("views/exam/master/programme/ExamConfiguration/ExamConfiguration")
    ),
    authority: [],
  },
  {
    key: "exam.entry.course.programme-wise-tenure-period",
    path: "/exam/entry/course/programme-wise-tenure-period",
    component: React.lazy(() =>
      import("views/exam/entry/course/ProgrammeWiseTenure/ProgrammeWiseTenure")
    ),
    authority: [],
  },
];
